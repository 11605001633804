import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { MifStructure } from 'services/mifs/mifs.types';

import CheckboxGroup from 'shared/CheckboxGroup';

interface MifQuestionOneProps {
  onContinue: (v: string[]) => void;
  question: MifStructure['configs'][number];
  selected: string[];
}

// const ITEMS = [
//   { label: 'Migraines', value: 'migraines' },
//   { label: 'High blood pressure', value: 'high_blood_pressure' },
//   { label: 'Risk or history of heart disease', value: 'risk_or_history_of_heart_disease' },
//   { label: 'Risk or history of stroke', value: 'risk_or_history_of_stroke' },
//   {
//     label: 'Reduced endurance/strength during physical activity',
//     value: 'reduced_endurance_strength_during_physical_activity'
//   },
//   {
//     label: 'Prolonged muscle soreness or recovery time after physical activity',
//     value: 'prolonged_muscle_soreness_or_recovery_time_after_physical_activity'
//   },
//   { label: 'None of the above', value: 'none' }
// ];

const MifQuestionOne: React.FC<MifQuestionOneProps> = ({ selected, onContinue, question }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelect = (v: number | string) => {
    let newArray: string[] = [];
    const stringValue = String(v);
    if (v === 'none') {
      if (!selectedItems.includes('none')) {
        newArray = [v];
      }
    } else {
      newArray = selectedItems.includes(stringValue)
        ? selectedItems.filter((i) => i !== stringValue && i !== 'none')
        : [...selectedItems, stringValue].filter((i) => i !== 'none');
    }

    setSelectedItems(newArray);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onContinue(selectedItems);
  };

  useEffect(() => {
    if (selected.length) {
      setSelectedItems(selected);
    }
  }, []);

  return (
    <form className="flex min-h-full flex-col gap-6" onSubmit={handleSubmit}>
      <h2 className="text-m2xl font-bold text-primary-700 md:text-2xl">
        Do you have a <span className="underline">history of</span> or{' '}
        <span className="underline">currently have</span> any of the following conditions:
      </h2>
      <CheckboxGroup
        items={question.config.options}
        selectedItems={selectedItems}
        onSelect={handleSelect}
      />
      <Common.Button
        className="py-6 md:self-center"
        color="blue"
        disabled={!selectedItems.length}
        fullWidthOnMobile
      >
        Continue
      </Common.Button>
    </form>
  );
};

export default MifQuestionOne;
