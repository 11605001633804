import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common/Icon';

import { useLazyGetMpMagicLinkQuery } from 'services/patientChart/patientChart';

import { selectUser } from 'store';

import Grid from 'widgets/myChart/Grid';

import {
  LINK_TO_ACCESSIBILITY,
  LINK_TO_SUPPORT,
  SIGNUP_CONFIRM_LINKS
} from 'constants/externalLinks';
import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useFreemium from 'hooks/useFreemium';
import { useLogout } from 'hooks/useLogout';
import useWidth from 'hooks/useWidth';
import { ACCOUNT_SETTINGS_LINKS } from 'utils/constants';
import { PathName } from 'utils/enums';

import packageInfo from '../../../package.json';

const AccountSettings = () => {
  useTitle('LifeMD - Account Settings');
  const logEvent = useAnalytics();
  const user = useAppSelector(selectUser);
  const { isMobile } = useWidth();
  const navigate = useNavigate();
  const isFreemium = useFreemium();
  const handleLogout = useLogout();
  const [getMpMagicLink, { data }] = useLazyGetMpMagicLinkQuery();
  const [openedSubMenu, setOpenedSubMenu] = useState<'Account Settings' | 'About' | null>(null);
  const isOptavia = user.companyPartners?.onboardingPartner?.partnerName === 'Optavia';

  const MY_ACCOUNT_LINKS = [
    {
      icon: 'patient-filled',
      name: 'Account Settings',
      path: PathName.AccountInformation
    },
    ...(data?.data.mpLink
      ? [{ icon: 'prescription', name: 'Member portal', path: data?.data.mpLink }]
      : []),
    {
      icon: 'info-person-filled',
      name: 'Support',
      path: isOptavia ? PathName.Support : LINK_TO_SUPPORT
    },
    {
      icon: 'info',
      name: 'About',
      path: ''
    }
  ];

  const handleClickLink = (name: string) => {
    switch (name) {
      case 'Account Settings':
        logEvent('my_account_settings_mitem_click');
        return setOpenedSubMenu('Account Settings');
      case 'Member portal':
        logEvent('my_account_member_portal_mitem_click');
        return window.open(data?.data.mpLink, '_blank');
      case 'Support':
        logEvent('my_account_support_mitem_click');
        return isOptavia ? navigate(PathName.Support) : window.open(LINK_TO_SUPPORT, '_blank');
      case 'About':
        logEvent('my_account_about_mitem_click');
        return setOpenedSubMenu('About');
    }
  };

  const handleUpdatedUserId = () => {
    user.userId && getMpMagicLink(user.userId);
  };

  const handleClickAccountSettingsTab = (path: string) => {
    switch (path) {
      case PathName.AccountInformation:
        return logEvent('acc_settings_acc_info_item_click');
      case PathName.BillingDetails:
        return logEvent('acc_settings_billing_item_click');
      case PathName.NotificationsSettings:
        return logEvent('acc_settings_notifications_item_click');
      case PathName.ChangePassword:
        return logEvent('acc_settings_change_password_item_click');
    }
  };

  const handleClickSignOut = () => {
    logEvent('my_account_sign_out_btn_click');
    handleLogout();
  };

  useEffect(handleUpdatedUserId, [user.userId]);

  useEffect(() => {
    !isMobile && navigate(PathName.AccountInformation);
  }, [isMobile]);

  const navItemClassName =
    'flex items-center gap-2 py-4 w-full text-left text-mBase cursor-pointer font-semibold md:font-bold';
  const navIconClassName = 'size-5 text-gray';
  const darkGrayNavIconClassName = 'size-5 text-gray-700';

  return (
    <div>
      {openedSubMenu ? (
        <button
          className="flex min-w-fit items-center truncate py-6 text-mLg font-bold md:text-xl"
          data-testid="top_header_back_to_btn"
          onClick={() => setOpenedSubMenu(null)}
        >
          <Common.Icon
            className="mr-3 size-6 flex-none rounded-full border-gray-100 p-0.5 md:mr-2 md:border md:p-1 md:text-secondary"
            name="arrow-left-small"
          />
          <span className="truncate">{openedSubMenu}</span>
        </button>
      ) : (
        <div className="flex items-center gap-5 pb-4 text-mLg font-bold">
          <Common.ProfileImage size="2xl" src={user.profileImage} />
          <div className="flex-1">
            <p className="text-mLg font-bold text-gray-700">
              {user.firstName} {user.lastName}
            </p>
            <NavLink
              className="mt-1 text-mSm font-medium text-gray"
              to={PathName.AccountInformation}
              onClick={() => logEvent('my_account_edit_profile_text_click')}
            >
              Edit profile
            </NavLink>
          </div>
          <NavLink to={PathName.Dashboard}>
            <Common.Icon className={navIconClassName} name="close" />
          </NavLink>
        </div>
      )}
      <nav>
        {!openedSubMenu && (
          <>
            <h3 className="py-3 font-bold">My chart</h3>
            <Grid />
            <div className="mb-4 mt-8 h-px w-full bg-gray-200" />
            <h3 className="py-3 font-bold">My account</h3>
            {MY_ACCOUNT_LINKS.map((el) => (
              <button
                className={navItemClassName}
                key={el.path}
                onClick={() => handleClickLink(el.name)}
              >
                <div className="mr-2 flex size-9 items-center justify-center rounded-full bg-gray-100 md:mr-0">
                  <Common.Icon
                    className={darkGrayNavIconClassName}
                    name={el.icon as IconProps['name']}
                  />
                </div>
                <span className="flex-1">{el.name}</span>
                <Common.Icon className={navIconClassName} name="arrow-right" />
              </button>
            ))}
            <Common.Button
              className="mb-8 mt-12"
              color="blue"
              preIcon="log-out"
              fullWidthOnMobile
              onClick={handleClickSignOut}
            >
              Sign out
            </Common.Button>
          </>
        )}
        {openedSubMenu === 'Account Settings' &&
          ACCOUNT_SETTINGS_LINKS.filter(
            (el) => !(el.path === PathName.BillingDetails && isFreemium)
          ).map((el) => (
            <NavLink
              className={navItemClassName}
              key={el.path}
              to={el.path}
              onClick={() => handleClickAccountSettingsTab(el.path)}
            >
              <Common.Icon className={darkGrayNavIconClassName} name={el.icon} />
              <span className="flex-1">{el.name}</span>
              <Common.Icon className={navIconClassName} name="arrow-right" />
            </NavLink>
          ))}
        {openedSubMenu === 'About' && (
          <div className="flex flex-col">
            <a className={navItemClassName} href={SIGNUP_CONFIRM_LINKS.privacy}>
              <Common.Icon className={darkGrayNavIconClassName} name="lock" />
              <span className="flex-1">Privacy policy</span>
              <Common.Icon className={navIconClassName} name="arrow-right" />
            </a>
            <a
              className={navItemClassName}
              data-acsb-custom-trigger="true"
              href={LINK_TO_ACCESSIBILITY}
            >
              <Common.Icon className={darkGrayNavIconClassName} name="accessibility" />
              <span className="flex-1">Accessibility</span>
              <Common.Icon className={navIconClassName} name="arrow-right" />
            </a>
            <span className="mx-auto mt-8 text-gray">v{packageInfo.version}</span>
          </div>
        )}
      </nav>
    </div>
  );
};
export default AccountSettings;
