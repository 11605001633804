import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';

import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import {
  useGetCreditBalanceQuery,
  useLazyGetCreditCardInformationQuery,
  useLazyGetMyAccountQuery,
  useUpdateDefaultPaymentMethodMutation
} from 'services/myAccount/myAccount';

// import { PaymentMethod } from 'models/payments.types';
import { selectLookup, selectUser } from 'store';

import BillingHistory from 'pages/MyAccount/components/BillingDetails/BillingHistory';
import PlanDetails from 'pages/MyAccount/components/BillingDetails/PlanDetails';

import DowngradeAccount from 'features/DowngradeAccount';
import PaymentMethods from 'features/PaymentMethods';
import ChangePlanWarning from 'modals/ChangePlanWarning';
import UpgradeToUnlimited from 'modals/UpgradeToUnlimited';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
// import { notifySuccess } from 'shared/Toast/Toast';
import { notifySuccess } from 'shared/Toast/Toast';

import { TRIPLE_THERAPY_PLAN_NAME } from 'constants/defaults';
import { useAppSelector, useQuery } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { useExpiredCard } from 'hooks/useExpiredCard';
import useWeightManagement from 'hooks/useWeightManagement';
import { DateFormat, FeatureFlag, PathName, PlanCodes } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { PaymentMethod } from 'models/payments.types';

const BillingDetails = () => {
  const logEvent = useAnalytics();
  const { isTTPatient } = useWeightManagement();
  const [changePlanWarning, toggleChangePlanWarning] = useToggle(false);
  const [isOpen, setIsOpen] = useToggle(false);
  const isEnabledNewDesign = useFlag(FeatureFlag.BillingDetails);
  const isEnabledBillingHistory = useFlag(FeatureFlag.BillingHistory);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { daysUntilCardExpiration, isExpired } = useExpiredCard();
  const { membershipPlans } = useAppSelector(selectLookup);
  const {
    activePlanId,
    activePlanCode,
    nextSubscriptionRenewalAt,
    nextPlanCode,
    activePricePoint,
    activePlanName,
    hasUnpaidInvoice
  } = useAppSelector(selectUser);

  useGetMembershipPlansQuery();
  const { data: balance } = useGetCreditBalanceQuery();
  const creditBalance = balance?.data.creditBalance;
  const [getPaymentMethodsInformation, { data: paymentMethods, isLoading, isFetching }] =
    useLazyGetCreditCardInformationQuery();
  const [getPaymentMethodsInformationInsurance, { data: insurancePaymentMethods }] =
    useLazyGetCreditCardInformationQuery();
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();
  const [getMyAccount] = useLazyGetMyAccountQuery();

  const currentPlan = membershipPlans.find((plan) => plan._id === activePlanId);
  const currentPP = currentPlan?.pricePoints?.find(
    (pp) => pp.planPricePointId === activePricePoint
  );
  const nextPlanName = membershipPlans.find((plan) => plan.planCode === nextPlanCode)?.planName;
  const query = useQuery();
  const action = query.get('action');

  const showDowngradeButton =
    !!activePlanCode &&
    activePlanCode !== PlanCodes.FlexCare &&
    nextPlanCode !== PlanCodes.FlexCare;

  const confirmUpgradeMessage = () => {
    setIsOpen(false);
  };

  const handleFavoritePaymentMethod = (id: string, cb?: () => void, activeTab?: string) => {
    if (id) {
      updateDefaultPaymentMethod({
        id,
        isInsuranceSubscription: isInsuranceUser && activeTab === 'appointments'
      })
        .unwrap()
        .then((r) => {
          (isInsuranceUser && activeTab === 'appointments'
            ? getPaymentMethodsInformationInsurance({ isInsuranceSubscription: true })
            : getPaymentMethodsInformation()
          )
            .unwrap()
            .then(() => {
              notifySuccess(r.message ?? 'Updated default payment method');
              getMyAccount().unwrap();
              cb?.();
            });
        })
        .catch(handleRequestCatch);
    }
  };

  const handleChangePlan = () => {
    if (activePlanCode === PlanCodes.WeightManagementMembership) {
      toggleChangePlanWarning(true);
    } else {
      navigate({ pathname: PathName.ChangePlan, search });
    }
  };

  const cancelAlert = () => (
    <Common.Alert type="warning" colorableBackground>
      <span className="font-bold">Your request has been processed.</span> Effective{' '}
      {dayjs(nextSubscriptionRenewalAt).format(DateFormat.MMM_DD)}, you will be downgraded to{' '}
      {nextPlanCode === PlanCodes.FlexCare ? 'a free' : `the ${nextPlanName}`} plan.
    </Common.Alert>
  );

  const isInsuranceUser = membershipPlans
    .find((plan) => plan.planCode === activePlanCode)
    ?.pricePoints.find((pp) => pp.planPricePointId === activePricePoint)
    ?.categories?.includes('insurance');

  useEffect(() => {
    getPaymentMethodsInformation()
      .unwrap()
      .then(() => {
        if (action === 'card-update') {
          navigate(PathName.PaymentMethods + '/edit');
        } else if (action === 'add-card') {
          navigate(PathName.PaymentMethods + '/add');
        }
      })
      .catch(handleRequestCatch);
    if (query.get('m') === 'unlimited') {
      setIsOpen(true);
      navigate('', { replace: true });
    }
    if (isInsuranceUser) {
      getPaymentMethodsInformationInsurance({ isInsuranceSubscription: true }).unwrap();
    }
  }, []);

  const headerClassName = 'font-bold mb-4 md:text-xl md:text-primary-700';

  const getPaymentMethods = (): PaymentMethod[] => {
    let arr: PaymentMethod[] =
      paymentMethods?.data.map((paymentMethod) => ({
        ...paymentMethod,
        type: 'membership'
      })) ?? [];
    if (isInsuranceUser) {
      const insuranceMethods: PaymentMethod[] =
        insurancePaymentMethods?.data.map((paymentMethod) => ({
          ...paymentMethod,
          type: 'appointments'
        })) ?? [];
      arr.push(...insuranceMethods);
    }
    return arr ?? [];
  };

  const handleViewAndPay = () => {
    navigate({
      pathname: PathName.UnpaidInvoice,
      search: `?invoiceId=${hasUnpaidInvoice}`
    });
  };

  return (
    <FadeWrapper className="flex flex-col gap-4 md:gap-10 md:p-8">
      <Loader isVisible={isFetching} />
      {!!currentPlan && daysUntilCardExpiration < 30 && (
        <Common.Alert type={isExpired ? 'error' : 'warning'} colorableBackground>
          <b>{isExpired ? 'Billing past due' : 'Your credit card is about to expire'}.&nbsp;</b>
          {isExpired
            ? 'Please update your payment method to access prescription renewals, appointments, and care team messages.'
            : `Update your payment method to continue using ${currentPlan.planName} features.`}
        </Common.Alert>
      )}
      <ChangePlanWarning
        activePlanName={isTTPatient ? TRIPLE_THERAPY_PLAN_NAME : activePlanName}
        isOpen={changePlanWarning}
        onClose={toggleChangePlanWarning}
        onSelect={() => navigate({ pathname: PathName.ChangePlan, search })}
      />
      <UpgradeToUnlimited confirmBtnText="Done" isOpen={isOpen} onClose={confirmUpgradeMessage} />
      {hasUnpaidInvoice && (
        <Common.Alert type="warning" colorableBackground>
          <p>
            <b>Your account has an unpaid invoice. </b>
            Please complete your payment to continue using the LifeMD platform.
          </p>
          <Common.Button className="mt-2" color="white-alt" onClick={handleViewAndPay}>
            View and pay
          </Common.Button>
        </Common.Alert>
      )}
      {!!nextPlanCode ? (
        <div className="mb-10 max-md:hidden">{cancelAlert()}</div>
      ) : (
        isEnabledNewDesign &&
        !!currentPlan &&
        !!currentPP && (
          <div>
            {<h2 className={headerClassName}>Plan details</h2>}
            <div data-testid="plan_details_section">
              <PlanDetails
                activePP={currentPP}
                currentPlan={currentPlan}
                onChangePlan={handleChangePlan}
              />
            </div>
          </div>
        )
      )}
      {!isLoading && (
        <div>
          <h2 className={headerClassName}>Payment method</h2>
          {!!nextSubscriptionRenewalAt && !!nextPlanCode && (
            <div className="mb-4 md:hidden">{cancelAlert()}</div>
          )}
          <div className="flex flex-col gap-4" data-testid="payment_method_section">
            <PaymentMethods
              creditBalance={creditBalance}
              isInsuranceUser={isInsuranceUser}
              isLoading={isUpdatingDefaultPaymentMethod || isFetching}
              paymentMethods={getPaymentMethods() ?? []}
              onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
            />
          </div>
        </div>
      )}
      {isEnabledBillingHistory && <BillingHistory />}
      {showDowngradeButton && (
        <DowngradeAccount onClick={() => logEvent('billing_cancel_memb_btn_click')} />
      )}
    </FadeWrapper>
  );
};

export default BillingDetails;
