import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { Props } from './radioGroup.types';

const RadioGroup = <T extends string | number | boolean>({
  isLoading,
  items,
  onSelect,
  selectedItem,
  wrapperClassName = '',
  textAreaValue,
  onTextAreaChange
}: Props<T>) => {
  return (
    <div className={classNames('flex flex-col gap-2', wrapperClassName)}>
      {items.map((item) => (
        <div
          // removed progress-wrapper class for now as client asked for it
          className={classNames(item.value === selectedItem && isLoading ? 'progress-wrapper' : '')}
          key={typeof item.label === 'string' ? item.label : String(item.value)}
        >
          <Common.RichRadioButton
            checked={item.value === selectedItem}
            disabled={isLoading || item.disabled}
            label={
              item.description || item.subLabel ? (
                <div className="flex flex-col gap-0.5">
                  {item.subLabel &&
                    (typeof item.subLabel === 'string' ? (
                      <span className="text-mSm text-primary-400">{item.subLabel}</span>
                    ) : (
                      item.subLabel
                    ))}
                  <span className="font-semibold">{item.label}</span>
                  <span className="text-mSm text-gray">{item.description}</span>
                </div>
              ) : (
                item.label
              )
            }
            name={String(item.value)}
            onChange={() => onSelect(item.value)}
          >
            {selectedItem === item.value && !!item.textArea && (
              <Common.TextArea
                dataTestId="text_area"
                disabled={isLoading}
                name={String(item.value)}
                placeholder={item.textArea.placeholder || ''}
                value={textAreaValue?.find((i) => i.option === item.value)?.value || ''}
                onChange={(v) => onTextAreaChange?.(String(v.target.value), String(item.value))}
              />
            )}
          </Common.RichRadioButton>
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
