import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import { selectToken } from 'store';

import { FLOW_TYPES } from 'constants/onboarding';
import { useAppSelector, useQuery } from 'hooks';
import { useRouteMatch } from 'hooks/useRouteMatch';
import useWeightManagement from 'hooks/useWeightManagement';
import { appendAppBanner } from 'utils/appendScript';
import { FlowTypes, PathName, PlanTypes, SignUpSteps } from 'utils/enums';

const Router = () => {
  const prevLocation = useLocation();
  const authenticated = useAppSelector(selectToken);
  const { isAsyncPlan, isWeightManagement } = useWeightManagement();
  const query = useQuery();
  const isSignUp = useRouteMatch(PathName.SignUp);
  const isVerifyEmailResult = !!useRouteMatch(PathName.VerifyEmailResult);

  const protectedRoutes = [
    PathName.Login,
    PathName.SignUp,
    PathName.ForgotPassword,
    PathName.CreateNewPassword,
    PathName.SymptomChecker,
    PathName.CreateFreeAccount,
    PathName.VerifyEmailResult,
    PathName.ConfirmAccount
  ];

  const isNotProtectedRoute = protectedRoutes.some((route) => useRouteMatch(route));

  const navigateTo = (to: string) => {
    const queryString = query.toString();
    if (queryString.length > 0) {
      to += (to.includes('?') ? '&' : '?') + queryString;
    }
    return <Navigate to={to} replace />;
  };

  if (!isNotProtectedRoute && !authenticated) {
    const redirectTo =
      prevLocation.pathname !== PathName.Dashboard
        ? `${PathName.Login}?redirectTo=${prevLocation.pathname}`
        : PathName.Login;
    return navigateTo(redirectTo);
  }

  if (isNotProtectedRoute && authenticated && !query.get('login-token')) {
    const redirectTo =
      query.get('redirectTo') ||
      (isWeightManagement || isAsyncPlan ? PathName.WeightManagement : PathName.Dashboard);
    query.delete('redirectTo');
    query.delete('token');
    if (!isVerifyEmailResult) return navigateTo(redirectTo);
  }

  !isSignUp && appendAppBanner();

  return <Outlet />;
};

export default Router;

export const SignupRouter = () => {
  const isAuthenticated = useAppSelector(selectToken);
  const { search } = useLocation();
  const { plan = PlanTypes.WeightManagement, flow = FlowTypes.WeightManagementFlow } = useParams();

  const getFirstStep = (flow?: FlowTypes) => {
    if (!flow || !FLOW_TYPES.hasOwnProperty(flow)) {
      return SignUpSteps.AppointmentCategory;
    }
    return FLOW_TYPES[flow][0];
  };

  const redirectPath = isAuthenticated
    ? PathName.Login
    : `${PathName.SignUp}/${plan}/${flow}/${getFirstStep(flow as FlowTypes) + search}`;

  return <Navigate to={redirectPath} replace />;
};
