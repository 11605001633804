import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import InputMask from '@mona-health/react-input-mask';
import { Common } from '@thecvlb/design-system';

const formatPhone = (value: string) => {
  let phone = value?.includes('+1') ? value.replace('+1', '') : value;
  if (phone && phone[0] !== '1') {
    phone = `1${phone}`;
  }
  return phone;
};

const PhoneInput = <TFormValues extends FieldValues>({
  name,
  control,
  dataTestId,
  label,
  className,
  hint,
  size
}: TFormValues) => {
  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name,
    rules: {
      required: {
        message: `Phone is required`,
        value: true
      },
      validate: {
        isValidPhone: (phone: string) => {
          return (phone && isValidPhoneNumber(phone)) || 'Phone is invalid';
        }
      }
    }
  });

  return (
    <InputMask
      mask="+1 999 999 9999"
      maskPlaceholder={null}
      value={formatPhone(field.value)}
      onChange={field.onChange}
    >
      <Common.Input
        className={className}
        dataTestId={dataTestId}
        errors={fieldState.error}
        helper={fieldState.error?.message}
        hint={hint}
        label={label}
        placeholder="+1 000-000-0000"
        ref={field.ref}
        size={size}
        type="tel"
      />
    </InputMask>
  );
};

export default PhoneInput;
