import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useLazyGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import { MifStructure } from 'services/mifs/mifs.types';

import { selectMifInfo } from 'store';
import { clearNutritionMif } from 'store/mif/mifSlice';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';

export const useNutritionMif = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { mif = '' } = useParams();
  const stepFromQuery = useQuery().get('s') ?? '';
  const redirectStep = useQuery().get('redirectStep') ?? 'date-time';

  const { nutritionMif = [] } = useAppSelector(selectMifInfo);

  const [getStructure, { isUninitialized, isLoading: initialLoading }] =
    useLazyGetMifStructureQuery();
  const [submitMif, { isLoading: isSubmittingResults }] = useSendMifResponseMutation();

  const [mifSteps, setMifSteps] = useState<MifStructure['configs']>([]);

  const getStepNames = () => {
    return ['intro', ...mifSteps.map((step) => String(step.config.question.value)), 'results'];
  };

  const handleSubmit = () => {
    submitMif({ id: mif, body: nutritionMif })
      .unwrap()
      .then(() => {
        dispatch(clearNutritionMif());
        const redirectStep = sessionStorage.getItem('redirectStep') ?? 'date-time';
        navigate(PathName.CreateAppointment + `?s=${redirectStep}`);
      });
  };

  const activeStep =
    stepFromQuery === 'intro'
      ? 'intro'
      : stepFromQuery === 'results'
        ? 'results'
        : mifSteps.find((step) => step.config.question.value === stepFromQuery);

  const progress = (): number => {
    if (typeof activeStep === 'string') {
      if (activeStep === 'intro') {
        return 0;
      }
      if (activeStep === 'results') {
        return getStepNames().length;
      }
      return 0;
    }

    return (
      getStepNames().findIndex((step) => step === String(activeStep?.config.question.value)) ?? 0
    );
  };

  const moveToStep = (step: 'next' | 'prev' | (string & {}), search?: string) => {
    const buildSearch = (originalSearch: string) => originalSearch + (search ? `&${search}` : '');

    if (step === 'next') {
      const nextStep = getStepNames()[progress() + 1];
      return navigate({ search: buildSearch(`s=${nextStep}`) });
    } else if (step === 'prev') {
      return navigate(-1);
    } else {
      if (getStepNames().includes(step)) {
        return navigate({ search: buildSearch(`s=${step}`) });
      }
      return navigate({ search: buildSearch(`s=${getStepNames()[0]}`) });
    }
  };

  useEffect(() => {
    getStructure(mif)
      .unwrap()
      .then(({ data }) => {
        setMifSteps(data.configs.toSorted((a, b) => a.order - b.order));
      })
      .finally(() => {
        if (redirectStep) {
          sessionStorage.setItem('redirectStep', redirectStep);
        }
        if (!stepFromQuery) {
          navigate(
            { search: `s=${getStepNames()[0]}` },
            {
              replace: true
            }
          );
        }
      });
  }, []);

  return {
    steps: mifSteps,
    currentQuestion: activeStep,
    loading: initialLoading,
    isUninitialized,
    activeStepIndex: progress(),
    flowLength: getStepNames().length,
    moveToStep,
    handleSubmit,
    isSubmittingResults
  };
};
