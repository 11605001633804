import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { MifStructure } from 'services/mifs/mifs.types';

import { MifStateProps } from 'store/mif/mif.types';

import Heading from 'pages/SignUp/WM/parts/Heading';

interface NutritionMifSummaryProps {
  answers: MifStateProps['nutritionMif'];
  loading?: boolean;
  onClickContinue: () => void;
  onClickEdit: (id: string) => void;
  questions: MifStructure['configs'];
}

const MifItem = ({
  question,
  answer,
  onEdit,
  id,
  textAreaAnswers
}: {
  answer?: string | string[];
  id: string;
  onEdit: (id: string) => void;
  question: MifStructure['configs'][number]['config'];
  textAreaAnswers?: { option: string; value: string }[];
}) => {
  const parsedAnswer = (): JSX.Element => {
    switch (typeof answer) {
      case 'string':
        const option = question.options.find((o) => o.value === answer)?.label;
        return <span>{option}</span>;
      case 'object':
        return (
          <>
            {answer.map((a, i) => {
              const option = question.options.find((o) => o.value === a);
              return (
                <span className="block" key={i}>
                  {!option?.textArea
                    ? option?.label
                    : `Other, ${textAreaAnswers?.find((t) => t.option === option?.value)?.value}`}
                </span>
              );
            })}
          </>
        );
      default:
        return <>{answer}</>;
    }
  };

  return (
    <div
      className={classNames('flex flex-col gap-1 pb-6', {
        'border-b border-gray-200': true
      })}
    >
      <div className="flex items-center gap-1">
        <Common.Icon className="mr-1 text-secondary" name="check"></Common.Icon>
        <span className="font-bold text-gray-700">{question.question.label}</span>
        <button
          className="ml-auto mr-3 block text-sm font-bold text-gray"
          data-testid="edit_btn"
          onClick={() => onEdit(id)}
        >
          Edit
        </button>
      </div>
      <span className="ml-6">{parsedAnswer()}</span>
    </div>
  );
};

const NutritionMifSummary: React.FC<NutritionMifSummaryProps> = ({
  answers,
  onClickContinue,
  onClickEdit,
  loading,
  questions
}) => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col gap-6">
        <Heading
          category="Summary"
          subtitle="Please review your responses."
          title="Check your answers"
        />
        <div className="rounded-2xl border border-gray-200 bg-white p-4">
          <div className="flex flex-col gap-6">
            {questions
              .toSorted((a, b) => a.order - b.order)
              .map((item) => {
                const answer = answers.find((a) => a.question === item.config.question.value);
                return (
                  <MifItem
                    answer={answer?.answer}
                    id={item.config.question.value}
                    key={item.config.question.value}
                    question={item.config}
                    textAreaAnswers={answer?.textAreaFields}
                    onEdit={onClickEdit}
                  />
                );
              })}
          </div>
        </div>
        <Common.Button
          className="mx-auto max-md:sticky max-md:bottom-4"
          color="blue"
          isLoading={loading}
          fullWidthOnMobile
          onClick={onClickContinue}
        >
          Continue
        </Common.Button>
      </div>
    </div>
  );
};

export default NutritionMifSummary;
