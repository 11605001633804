import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { useUploadFileMutation } from 'services/channels/channels';
import { UploadFileDataProps, UploadFileResProps } from 'services/channels/channels.types';
import { useGetGlobalMessagesQuery } from 'services/general/general';

import { selectUser } from 'store';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import ChatDate from 'widgets/Chat/ChatDate';
import ChatForm from 'widgets/Chat/ChatForm';
import Message from 'widgets/Chat/Message';

import { DateMessagesProps, UserTypeProps } from 'contexts/messagesContext/messagesContext.types';
import { useAppSelector, useFrontDesk, useMessages, useQuery } from 'hooks';
import { DEFAULT_MESSAGE } from 'utils/constants';
import { handleRequestCatch } from 'utils/helpers';

import { ChatProps } from './chat.types';

const Chat: React.FC<ChatProps> = ({ isVideoCall, isZoomCall }) => {
  const [uploadFile, { isLoading }] = useUploadFileMutation();
  const { data } = useGetGlobalMessagesQuery();

  const {
    dateMessages,
    channels,
    channelDetails,
    generalHealth,
    isLoading: isLoadingMessages,
    joinRoom,
    closeChannel,
    sendMessage,
    getOldMessage,
    markSeen
  } = useMessages();
  const {
    dateMessages: dateMessagesFrontDesk,
    channels: channelsFrontDesk,
    channelDetails: channelDetailsFrontDesk,
    isLoading: isLoadingFrontDesk,
    joinRoom: joinRoomFrontDesk,
    closeChannel: closeChannelFrontDesk,
    sendMessage: sendMessageFrontDesk,
    getOldMessage: getOldMessageFrontDesk,
    markSeen: markSeenFrontDesk
  } = useFrontDesk();
  const query = useQuery();
  const chatId = isVideoCall || isZoomCall ? generalHealth : query.get('chatId');
  const isFrontDesk = !channels.find((el) => el.channelId === chatId);
  const { userId, userType } = useAppSelector(selectUser);
  const [skip, setSkip] = useState(1);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollBlock = useRef<HTMLDivElement | null>(null);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleSendMessage = (
    input: string,
    file: UploadFileDataProps | null,
    reset: () => void
  ) => {
    if (channelDetails || channelDetailsFrontDesk) {
      const data = {
        message: input,
        ...file,
        senderDetails: { id: userId, userType: userType as UserTypeProps }
      };
      if (channelDetailsFrontDesk) {
        sendMessageFrontDesk({
          ...data,
          channelId: channelDetailsFrontDesk?._id,
          receiverDetails: channelDetailsFrontDesk?.staffDetail
        });
      }
      if (channelDetails) {
        sendMessage({
          ...data,
          channelId: channelDetails._id,
          receiverDetails: channelDetails.staffs
        });
      }
      reset();
      scrollBlock.current?.scrollTo && scrollBlock.current.scrollTo(0, 0);
    }
  };

  const uploadFileForChat = (file: File): Promise<void | UploadFileResProps> => {
    const formData = new FormData();
    formData.append('chatFile', file);
    return uploadFile(formData).unwrap().catch(handleRequestCatch);
  };

  const onSubmit = async (message: string, files: File[], reset: () => void) => {
    if (files.length) {
      for (const [index, fileSelected] of files.entries()) {
        setDisabledSubmit(true);
        const file = await uploadFileForChat(fileSelected);
        setDisabledSubmit(false);
        if (!file) {
          continue;
        }
        handleSendMessage(files.length - 1 === index ? message.trim() : '', file.data, reset);
      }
    } else {
      handleSendMessage(message.trim(), null, reset);
    }
  };

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    setScrollTop(e.currentTarget.scrollTop);
  };

  const handleJoinRoom = () => {
    if (!isFrontDesk && channels.length && !channelDetails && chatId) {
      joinRoom(chatId);
    }
  };

  const handleJoinRoomFrontDesk = () => {
    if (isFrontDesk && channelsFrontDesk.length && !channelDetailsFrontDesk && chatId) {
      joinRoomFrontDesk(chatId);
    }
  };

  const handleUpdatedScrollTop = () => {
    if (!scrollBlock.current) return;

    const { offsetHeight } = scrollBlock.current;
    const scrollHeight = Math.round(Math.abs(scrollTop) + offsetHeight);

    if (scrollHeight + 1 <= scrollBlock.current.scrollHeight) return;

    const channelId = isFrontDesk ? channelDetailsFrontDesk?._id : channelDetails?._id;
    const limit = 10;

    if (chatId !== channelId) return;

    const getMessage = isFrontDesk ? getOldMessageFrontDesk : getOldMessage;
    getMessage({ channelId, limit, skip }, () => setSkip(skip + 1));
  };

  const handleMarkSeen = () => {
    if (channelDetails && !(isVideoCall || isZoomCall)) {
      const channel = channels.find((item) => item.channelId === channelDetails._id);
      if (channel?.unreadMessageCount) {
        markSeen({ channelId: channel.channelId, userId });
      }
    }
  };

  const handleMarkSeenFrontDesk = () => {
    if (channelDetailsFrontDesk) {
      const channel = channelsFrontDesk.find(
        (item) => item.channelId === channelDetailsFrontDesk._id
      );
      if (channel?.unreadMessageCount) {
        markSeenFrontDesk({ channelId: channelDetailsFrontDesk._id, userId });
      }
    }
  };

  useEffect(handleMarkSeen, [channels, channelDetails]);

  useEffect(handleMarkSeenFrontDesk, [channelsFrontDesk, channelDetailsFrontDesk]);

  useEffect(handleJoinRoom, [chatId, channels, channelDetails]);

  useEffect(handleJoinRoomFrontDesk, [chatId, channelsFrontDesk, channelDetailsFrontDesk]);

  useEffect(handleUpdatedScrollTop, [scrollTop, channelDetails, channelDetailsFrontDesk]);

  useEffect(() => {
    setSkip(1);
    closeChannel();
    closeChannelFrontDesk();
  }, [chatId]);

  const messages = isFrontDesk ? dateMessagesFrontDesk : dateMessages;
  const loading = isFrontDesk ? isLoadingFrontDesk : isLoadingMessages;

  return (
    <>
      <Loader isVisible={isLoading || ((loading || messages === null) && !!chatId)} />
      <div
        className={classNames(
          'flex flex-col overflow-hidden',
          isZoomCall
            ? 'h-full md:h-[calc(100%_-_87px)]'
            : isVideoCall
              ? 'h-[calc(100%_-_135px)] md:h-[calc(100%_-_80px)]'
              : 'h-full md:h-[calc(100%_-_80px)]'
        )}
        data-testid="chat_content"
      >
        <div
          className="relative flex flex-1 flex-col-reverse overflow-auto px-4 md:p-0"
          data-testid="messages_scroll_area"
          ref={scrollBlock}
          onScroll={handleScroll}
        >
          <div className="flex-1">
            {!loading && messages?.length === 0 && (
              <>
                {isFrontDesk ? (
                  <div className="mx-auto mt-8 w-fit rounded-lg bg-gray-100 px-[18px] py-10 md:bg-gray-50 md:p-10">
                    <Common.Illustration className="mx-auto max-w-[307px]" name="messages-empty" />
                    <p className="text-center italic text-gray-500">
                      No requests have been submitted.
                    </p>
                  </div>
                ) : (
                  <>
                    <ChatDate date={new Date().toDateString()} />
                    <Message
                      combined={false}
                      key={'message._id'}
                      message={DEFAULT_MESSAGE}
                      mobile={!!isVideoCall || !!isZoomCall}
                      self={false}
                    />
                  </>
                )}
              </>
            )}
            {messages?.map((el: DateMessagesProps) => (
              <FadeWrapper className="relative" key={el.date}>
                <ChatDate date={el.date} />
                {el.messages.map((message, index) => {
                  let combined = false;
                  const nextMessage = el.messages[index + 1];
                  if (nextMessage) {
                    const minutes = dayjs(nextMessage.messageStatus.sent).diff(
                      message.messageStatus.sent,
                      'minutes'
                    );
                    combined =
                      nextMessage.senderDetails?.id === message.senderDetails?.id && minutes < 5;
                  }
                  return (
                    <Message
                      combined={combined}
                      key={message._id}
                      message={message}
                      mobile={!!isVideoCall || !!isZoomCall}
                      self={message.system || message.senderDetails?.id === userId}
                    />
                  );
                })}
              </FadeWrapper>
            ))}
          </div>
        </div>
        {!!chatId && (
          <div className="flex flex-col gap-4 md:p-4">
            {!(isVideoCall || isZoomCall) && data?.frontDescMessage && (
              <Common.Alert
                className="whitespace-normal max-md:mx-4 max-md:mt-4 max-md:text-mSm"
                type="error"
                colorableBackground
                onClose={() => {}}
              >
                {data.frontDescMessage}
              </Common.Alert>
            )}
            <ChatForm disabledSubmit={disabledSubmit} onSubmit={onSubmit} />
          </div>
        )}
      </div>
    </>
  );
};

export default Chat;
