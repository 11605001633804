/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import fileDownload from 'js-file-download';
import { nanoid } from 'nanoid';

import {
  useGetAdminPrescriptionsQuery,
  useGetPrescriptionsQuery
} from 'services/patientChart/patientChart';
import { useCreateCheckInSurveyMutation, useCreateRenewalMutation } from 'services/wm/wm';

import { selectPatientChart, selectPrescriptionsStatus, selectToken, selectUser } from 'store';

import AskToUpgradeToTheWM from 'modals/AskToUpgradeToTheWM';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import { notifyError } from 'shared/Toast/Toast';
import { downloadFile } from 'widgets/myChart/Documents/documents.settings';
import Prescription from 'widgets/Prescriptions/Prescription';

import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { PathName, PlanCodes, ShippingStatuses } from 'utils/enums';
import { handleRequestCatch, isAdminPrescription } from 'utils/helpers';

import { CombinedPrescriptionItem } from 'models/prescriptions.types';

const Prescriptions = () => {
  const accessToken = useAppSelector(selectToken);
  const logEvent = useAnalytics();
  const { userId, activePlanCode, hadOrHasWeightManagement } = useAppSelector(selectUser);
  const isDisabledPrescriptions = useAppSelector(selectPrescriptionsStatus);
  const { prescriptions, adminPrescriptions } = useAppSelector(selectPatientChart);

  const { isLoading: isLoadingPrescriptions } = useGetPrescriptionsQuery();
  const { isLoading: isLoadingAdmin } = useGetAdminPrescriptionsQuery(userId);
  const [createSurvey, { isLoading: createSurveyIsLoading }] = useCreateCheckInSurveyMutation();
  const [createRenewal, { isLoading: createRenewalIsLoading }] = useCreateRenewalMutation();
  const [isOpenUpgradePopup, toggleUpgradePopup] = useToggle(false);
  const navigate = useNavigate();
  const filteredPrescriptions = prescriptions.filter((e) => !e.isDocMed);
  const isRestrictedAccess =
    activePlanCode !== PlanCodes.WeightManagementMembership && hadOrHasWeightManagement;

  const onCreateRenewal = (prescription: CombinedPrescriptionItem) => {
    logEvent('prescriptions_request_renewal_btn_click');
    if (isRestrictedAccess) {
      toggleUpgradePopup();
    } else {
      !isAdminPrescription(prescription) &&
        prescription.orderId &&
        createRenewal({ orderId: prescription.orderId })
          .unwrap()
          .then((res) => {
            navigate({
              pathname: PathName.PrescriptionStatus,
              search: `orderId=${res.data.orderId}&type=${res.data.type}`
            });
          })
          .catch(handleRequestCatch);
    }
  };

  const onCreateSurvey = async (prescription: CombinedPrescriptionItem) => {
    logEvent('prescriptions_mark_received_btn_click');
    if (isAdminPrescription(prescription) || !prescription.trackingInfo) {
      return;
    }

    if (prescription.trackingInfo?.shippingStatus === ShippingStatuses.Delivered) {
      if (!prescription.survey?.checkInReceived && prescription.orderId) {
        try {
          await createSurvey({ orderId: prescription.orderId }).unwrap();
        } catch (e) {
          // @ts-ignore
          if (!(e.data.message || e.message).includes('already exists')) {
            throw e;
          }
        }
      }

      return navigate({
        pathname: PathName.PrescriptionStatus,
        search: `orderId=${prescription.orderId}&type=check-in`
      });
    }
  };

  const downloadPDF = async (title: string) => {
    try {
      const file = await downloadFile(accessToken, '64d1f1a339360d5d9f6db288');
      fileDownload(file, title);
    } catch (e) {
      notifyError((e as Error).message || 'Error, please try again');
    }
  };

  const printPDF = async () => {
    const blob = await downloadFile(accessToken, '64d1f1a339360d5d9f6db288');
    const blobURL = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow?.print();
      }, 1);
    };
  };

  return (
    <FadeWrapper className="rounded-2xl border border-gray-200 bg-white p-4 md:border-none md:p-0">
      <Loader isVisible={isLoadingPrescriptions || isLoadingAdmin} />
      <AskToUpgradeToTheWM
        alertContent={
          <>
            <b>Please upgrade your plan</b> to access renewals for your medication.
          </>
        }
        declineButtonText="Close"
        isOpen={isOpenUpgradePopup}
        hideAdditionalPopup
        onClose={toggleUpgradePopup}
      />
      {(filteredPrescriptions.length || adminPrescriptions.length) && !isDisabledPrescriptions ? (
        <div className="divide-y" data-testid="prescriptions_content">
          {[...filteredPrescriptions, ...adminPrescriptions].map((prescription) => (
            <Prescription
              createRenewalIsLoading={createRenewalIsLoading}
              createSurveyIsLoading={createSurveyIsLoading}
              key={nanoid()}
              prescription={prescription}
              onCreateRenewal={() => onCreateRenewal(prescription)}
              onCreateSurvey={() => onCreateSurvey(prescription)}
              onDownloadPDF={downloadPDF}
              onPrintPDF={printPDF}
            />
          ))}
        </div>
      ) : (
        !isLoadingPrescriptions && (
          <div
            className="flex flex-col items-center px-8 py-12 text-center md:py-8"
            data-testid="prescriptions_content"
          >
            <Common.Icon className="size-14 md:size-16" name="lifemd" />
            <h1 className="mt-4 font-bold">You don't have any prescriptions listed.</h1>
            <h2 className="text-gray">Prescriptions from your provider will appear here.</h2>
          </div>
        )
      )}
    </FadeWrapper>
  );
};

export default Prescriptions;
