import { Common } from '@thecvlb/design-system';

import AdvantageItem from 'features/AdvantageItem';

import { discount, freePlanCode, getHint, getPrice } from './planDetails.settings';
import { PlanDetailsProps } from './planDetails.types';

const PlanDetails: React.FC<PlanDetailsProps> = ({ currentPlan, activePP }) => {
  const priceInfo = () => {
    if (currentPlan.planCode === freePlanCode) {
      return 'Free';
    }

    const isInsurance = activePP.categories?.includes('insurance');

    return activePP.paymentInterval.qty === 1 && activePP.paymentInterval.units === 'months'
      ? `$${activePP.totalCost}/mo ${isInsurance ? '+ appt copay' : ''}`
      : `$${getPrice(activePP.planPricePointId, activePP.totalCost)}/every ${activePP.paymentInterval.qty} mo`;
  };

  const hasDiscount = discount[activePP.planPricePointId] !== undefined;

  return (
    <div className="rounded-xl border border-gray-200 bg-white p-4">
      <div className="flex flex-col justify-between">
        <h2 className="font-bold" data-testid="current_plan">
          {activePP.planName ?? currentPlan.planName}
        </h2>
        <div className="flex items-center justify-between">
          <div className="flex h-[25px] items-center">
            {hasDiscount && (
              <h2 className="mr-1 font-semibold text-gray-500 line-through">
                ${activePP.totalCost}
              </h2>
            )}
            <h2 className="font-semibold" data-testid="plan_price">
              {priceInfo()}
            </h2>
          </div>

          {hasDiscount ? (
            <Common.ColorTag
              color="secondary"
              text={`${discount[activePP.planPricePointId]} off`}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
      <div className="mb-2 mt-4 flex flex-col gap-2">
        {(activePP.additionalInfo ?? currentPlan.additionalInfo)
          .filter((v) => v.value)
          .map((advantage) => (
            <AdvantageItem
              advantage={advantage}
              className="text-base"
              key={advantage.question}
              showCustomIcon
            />
          ))}
      </div>
      {!!getHint(activePP.planPricePointId) && (
        <div className="py-2 text-[11px] font-medium leading-4 text-gray-500">
          {getHint(activePP.planPricePointId)}
        </div>
      )}
      {/* <Common.Button color="blue" dataTestId="change_plan" fullWidthOnMobile onClick={onChangePlan}>
        Change plan
      </Common.Button> */}
    </div>
  );
};

export default PlanDetails;
