import { Common } from '@thecvlb/design-system/lib/src';

import { MifStructure, NutritionQuestion } from 'services/mifs/mifs.types';

import { selectMifInfo } from 'store';
import { setNutritionMif } from 'store/mif/mifSlice';

import Heading from 'pages/SignUp/WM/parts/Heading';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import CheckboxGroup from 'shared/CheckboxGroup';
import RadioGroup from 'shared/RadioGroup';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';

interface DynamicQuestionProps {
  mifLength: number;
  onClickContinue: () => void;
  question: MifStructure['configs'][number];
}

const DynamicQuestion: React.FC<DynamicQuestionProps> = ({
  question,
  onClickContinue,
  mifLength
}) => {
  const dispatch = useAppDispatch();
  const { isEdit } = useMifNavigate();
  const { nutritionMif = [] } = useAppSelector(selectMifInfo);
  const answerOnCurrentQuestion = nutritionMif.find(
    (item) => item.question === question.config.question.value
  );

  const handleSubmitAnswer = (
    type: NutritionQuestion['config']['type'] | 'textarea',
    value: string,
    option?: string
  ) => {
    let payload: {
      answer: string | string[];
      question: string;
      textAreaFields?: { option: string; value: string }[];
    } = {
      question: '',
      answer: ''
    };
    switch (type) {
      case 'radio':
        payload = { question: question.config.question.value, answer: String(value) };
        break;
      case 'textarea':
        payload = {
          question: question.config.question.value,
          answer: answerOnCurrentQuestion?.answer as string[],
          textAreaFields: [
            ...(answerOnCurrentQuestion?.textAreaFields ?? [])?.filter((i) => i.option !== option),
            {
              option: option ?? '',
              value
            }
          ]
        };
        break;
      case 'checkbox':
        if (value === 'none') {
          payload = {
            question: question.config.question.value,
            answer: answerOnCurrentQuestion?.answer.includes(value) ? [] : [value],
            textAreaFields: undefined
          };
        } else {
          if (Array.isArray(answerOnCurrentQuestion?.answer)) {
            const newAnswer = answerOnCurrentQuestion.answer.includes(value)
              ? answerOnCurrentQuestion.answer.filter((v) => v !== value)
              : [...answerOnCurrentQuestion.answer, value];
            if (newAnswer.length > 1 && newAnswer.includes('none')) {
              newAnswer.splice(newAnswer.indexOf('none'), 1);
            }
            payload = { question: question.config.question.value, answer: newAnswer };
            if (answerOnCurrentQuestion?.textAreaFields && newAnswer.includes('other')) {
              payload.textAreaFields = answerOnCurrentQuestion.textAreaFields;
            }
          } else {
            payload = { question: question.config.question.value, answer: [value] };
          }
        }
        break;
      case 'text':
        payload = { question: question.config.question.value, answer: String(value) };
        break;
      default:
        break;
    }
    dispatch(setNutritionMif([payload]));

    const shouldPreventMoveToTheNextStep = () => {
      const option = question.config.options.find((i) => i.value === value);
      return ['checkbox', 'textarea'].includes(type) || (type === 'radio' && !!option?.textArea);
    };

    !shouldPreventMoveToTheNextStep() && onClickContinue();
  };

  const content = () => {
    switch (question.config.type) {
      case 'radio':
        return (
          <div className="flex h-full flex-col gap-8">
            <RadioGroup
              items={question.config.options}
              selectedItem={answerOnCurrentQuestion?.answer as string}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('radio', v)}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            {question.config.options.find((i) => i.value === answerOnCurrentQuestion?.answer)
              ?.textArea && (
              <Common.Button
                className="mx-auto mt-auto"
                color="blue"
                fullWidthOnMobile
                onClick={onClickContinue}
              >
                {isEdit ? 'Save' : 'Next'}
              </Common.Button>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <div className="flex h-full flex-col gap-8">
            <CheckboxGroup
              items={question.config.options}
              selectedItems={(answerOnCurrentQuestion?.answer as string[]) ?? []}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('checkbox', String(v))}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              disabled={!answerOnCurrentQuestion?.answer.length}
              fullWidthOnMobile
              onClick={onClickContinue}
            >
              {isEdit ? 'Save' : 'Next'}
            </Common.Button>
          </div>
        );
      case 'text':
        return (
          <Common.Input
            label={question.config.question.label}
            value={answerOnCurrentQuestion?.answer as string}
            onChange={(e) => handleSubmitAnswer('text', e.target.value)}
          />
        );
    }
  };

  return (
    <div className="flex h-full flex-col gap-6">
      <Heading
        category={`Question ${question.order} of ${mifLength}`}
        title={question.config.question.label}
      />
      {<SlideAnimateWrapper key={question.order}>{content()}</SlideAnimateWrapper>}
    </div>
  );
};

export default DynamicQuestion;
