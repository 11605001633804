import { selectMifInfo } from 'store';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';
import Header from 'widgets/Header';
import { DynamicQuestion, MoreAboutYou, NutritionMifSummary } from 'widgets/nutritionMif';

import { useAppSelector, useQuery } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';
import { useNutritionMif } from 'hooks/useNutritionMif';

const NutritionMif = () => {
  const currentStep = useQuery().get('s') ?? '';

  const { nutritionMif = [] } = useAppSelector(selectMifInfo);

  const {
    loading,
    steps,
    currentQuestion,
    isUninitialized,
    activeStepIndex,
    flowLength,
    moveToStep,
    handleSubmit,
    isSubmittingResults
  } = useNutritionMif();

  const { isEdit } = useMifNavigate();

  const handleContinue = () => {
    if (isEdit) {
      moveToStep('results');
    } else {
      moveToStep('next');
    }
  };

  const content = () => {
    switch (currentStep) {
      case 'intro':
        return (
          <SlideAnimateWrapper className="h-full" key="intro">
            <MoreAboutYou onClickContinue={() => moveToStep('next')} />
          </SlideAnimateWrapper>
        );
      case 'results':
        return (
          <SlideAnimateWrapper key="results">
            <NutritionMifSummary
              answers={nutritionMif}
              loading={isSubmittingResults}
              questions={steps}
              onClickContinue={handleSubmit}
              onClickEdit={(step) => moveToStep(step, 'step-action=edit')}
            />
          </SlideAnimateWrapper>
        );
      default:
        return (
          !!currentQuestion &&
          typeof currentQuestion !== 'string' && (
            <DynamicQuestion
              mifLength={steps.length}
              question={currentQuestion}
              onClickContinue={handleContinue}
            />
          )
        );
    }
  };

  return loading || isUninitialized ? (
    <Loader isVisible />
  ) : (
    <div className="relative flex grow flex-col p-4">
      <Header
        flowLength={flowLength}
        step={activeStepIndex}
        onClickPrev={() => moveToStep('prev')}
      />
      <div className="w-full max-w-[500px] grow md:mx-auto">{content()}</div>
    </div>
  );
};

export default NutritionMif;
