import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { MifStructure } from 'services/mifs/mifs.types';

import CheckboxGroup from 'shared/CheckboxGroup';

interface MifQuestionThreeProps {
  loading?: boolean;
  onContinue: (v: string[]) => void;
  question: MifStructure['configs'][number];
  selected: string[];
}

// const ITEMS = [
//   { label: 'Active asthma symptoms', value: 'active_asthma_symptoms' },
//   {
//     label: 'Recurrent episodes of herpes outbreaks',
//     value: 'recurrent_episodes_of_herpes_outbreaks'
//   },
//   { label: 'Chronically low blood pressure', value: 'chronically_low_blood_pressure' },
//   { label: 'None of the above', value: 'none' }
// ];

const MifQuestionThree: React.FC<MifQuestionThreeProps> = ({
  question,
  selected,
  onContinue,
  loading
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const handleSelect = (v: number | string) => {
    let newArray: string[] = [];
    const stringValue = String(v);
    if (v === 'none') {
      if (!selectedItems.includes('none')) {
        newArray = [v];
      }
    } else {
      newArray = selectedItems.includes(stringValue)
        ? selectedItems.filter((i) => i !== stringValue && i !== 'none')
        : [...selectedItems, stringValue].filter((i) => i !== 'none');
    }

    setSelectedItems(newArray);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onContinue(selectedItems);
  };

  useEffect(() => {
    if (selected.length) {
      setSelectedItems(selected);
    }
  }, []);

  return (
    <form className="flex min-h-full flex-col gap-6" onSubmit={handleSubmit}>
      <h2 className="text-m2xl font-bold text-primary-700 md:text-2xl">
        Do you <span className="underline">currently</span> have any of the following?
      </h2>
      <CheckboxGroup
        items={question.config.options}
        selectedItems={selectedItems}
        onSelect={handleSelect}
      />
      <Common.Button
        className="py-6 max-md:mt-auto md:self-center"
        color="blue"
        disabled={!selectedItems.length}
        isLoading={loading}
        fullWidthOnMobile
      >
        Continue
      </Common.Button>
    </form>
  );
};

export default MifQuestionThree;
