import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';

import {
  useDeselectPharmacyMutation,
  useSelectPharmacyMutation
} from 'services/pharmacies/pharmacies';

import { selectPrescriptionDiscountCardStatus, selectPrescriptionsStatus, selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import MyChartTitle from 'features/MyChartTitle';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import { notifySuccess } from 'shared/Toast/Toast';
import DiscountCard from 'widgets/myChart/Prescriptions/DiscountCard';
import Insurance from 'widgets/myChart/Prescriptions/Insurance';
import Pharmacy from 'widgets/Pharmacy';
import Prescriptions from 'widgets/Prescriptions';

import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { FeatureFlag } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const MyChartPrescriptions: React.FC = () => {
  useTitle('LifeMD - Prescriptions');
  const logEvent = useAnalytics();
  const navigate = useNavigate();
  const query = useQuery();
  const activeTab = query.get('tab') || '';

  const dispatch = useAppDispatch();
  const isEnabledPharmacyTabFeature = useFlag(FeatureFlag.PharmacyTab);
  const isEnabledPrescriptionDiscountCardFeature = useFlag(FeatureFlag.PrescriptionDiscountCard);
  const { accessToken, address, zipCode, state, city, pharmacy } = useAppSelector(selectUser);
  const [selectPharmacy, { isLoading: isSelectPharmacy }] = useSelectPharmacyMutation();
  const [deselectPharmacy, { isLoading: isDeselectPharmacy }] = useDeselectPharmacyMutation();

  const isDisabledPrescriptionDiscountCard = useAppSelector(selectPrescriptionDiscountCardStatus);
  const isDisabledPrescriptions = useAppSelector(selectPrescriptionsStatus);

  const tabs = [
    { label: 'Prescriptions' },
    ...(isEnabledPharmacyTabFeature && !isDisabledPrescriptions ? [{ label: 'Pharmacy' }] : []),
    { label: 'Insurance' },
    ...(isEnabledPrescriptionDiscountCardFeature && !isDisabledPrescriptionDiscountCard
      ? [{ label: 'Discount card' }]
      : [])
  ];

  const handleSelectedPharmacy = (pharmacyId?: number | string) => {
    if (!pharmacyId) return;
    selectPharmacy({ accessToken, pharmacyId: Number(pharmacyId) })
      .unwrap()
      .then(({ data, message }) => {
        dispatch(setUser({ pharmacy: data }));
        notifySuccess(message);
      })
      .catch((e) => handleRequestCatch(e, 'Error with setting pharmacy, please try again later'));
  };

  const handleDeselectedPharmacy = (pharmacyId?: number | string) => {
    if (!pharmacyId) return;
    deselectPharmacy({ accessToken, pharmacyId: Number(pharmacyId) })
      .unwrap()
      .then(({ message }) => {
        dispatch(setUser({ pharmacy: undefined }));
        notifySuccess(message);
      })
      .catch((e) => handleRequestCatch(e, 'Error with setting pharmacy, please try again later'));
  };

  const onChangeTab = (tabTitle: string) => {
    if (tabTitle === 'Discount card') logEvent('prescriptions_discount_card_tab_click');
    if (tabTitle === 'Insurance') logEvent('prescriptions_insurance_tab_click');
    if (tabTitle === 'Pharmacy') logEvent('prescriptions_pharmacy_tab_click');
    if (tabTitle === 'Prescriptions') logEvent('prescriptions_prescriptions_tab_click');
    navigate({
      search: `tab=${tabTitle}`
    });
  };

  return (
    <FadeWrapper className="flex flex-col md:p-8">
      <MyChartTitle icon="prescription-outline" text={'Prescriptions'} />
      <div className="hidden-scrollbar overflow-auto bg-gray-50 pb-4 max-md:-mr-4 md:bg-transparent md:pb-0">
        <Common.Tabs
          data={tabs}
          defaultSelected={[activeTab]}
          isWrap={false}
          type="bar"
          onChange={(el) => onChangeTab(el.label)}
        />
      </div>
      {activeTab === 'Prescriptions' && <Prescriptions />}
      {activeTab === 'Insurance' && <Insurance />}
      {activeTab === 'Pharmacy' && isEnabledPharmacyTabFeature && (
        <Pharmacy
          accessToken={accessToken}
          defaultCoords={{ lat: pharmacy?.latitude, lng: pharmacy?.longitude }}
          isLoading={isSelectPharmacy || isDeselectPharmacy}
          mailingAddress={{
            address,
            city,
            state,
            zipCode: pharmacy?.zipCode || zipCode
          }}
          userPharmacyID={pharmacy?.pharmacyId}
          onDeselect={handleDeselectedPharmacy}
          onSelect={handleSelectedPharmacy}
        />
      )}
      {activeTab === 'Discount card' && !isDisabledPrescriptionDiscountCard && <DiscountCard />}
    </FadeWrapper>
  );
};

export default MyChartPrescriptions;
