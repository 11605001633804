/* eslint-disable @typescript-eslint/ban-ts-comment */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { MifProps, MifStateProps } from 'store/mif/mif.types';

export const initialState: MifStateProps = {
  mifInfo: {
    allergyList: [],
    chronicDiseases: [],
    healthConditions: [],
    medications: { prescription: [], supplements: [] },
    preferredPharmacy: '',
    socialHistory: [],
    womenHealth: {
      approxStartDate: '',
      name: '',
      startDate: ''
    }
  },
  mifPicks: {
    allergiesPick: false,
    medicationsPick: false
  },
  nutritionMif: [],
  tirzepatideMif: []
};

const mifSlice = createSlice({
  initialState,
  name: 'mif',
  reducers: {
    clearMifState: (state) => {
      state.mifInfo = initialState.mifInfo;
    },
    setMifByCompleteAccount: (state, action: PayloadAction<{ data: MifProps }>) => {
      const { data } = action.payload;
      state.mifInfo = { ...state.mifInfo, ...data };
    },
    setMifInformation: (state, action: PayloadAction<{ data: unknown; prop: keyof MifProps }>) => {
      const { data, prop } = action.payload;
      // @ts-ignore
      state.mifInfo[prop] = data;
    },
    setMifPick: (state, action: PayloadAction<Partial<MifStateProps['mifPicks']>>) => {
      state.mifPicks = { ...state.mifPicks, ...action.payload };
    },
    setNutritionMif: (state, action: PayloadAction<MifStateProps['nutritionMif']>) => {
      const filteredArray = uniqBy([...action.payload, ...(state.nutritionMif ?? [])], 'question');
      state.nutritionMif = filteredArray;
    },
    setTirzepatideMif: (state, action: PayloadAction<MifStateProps['tirzepatideMif']>) => {
      const filteredArray = uniqBy(
        [...action.payload, ...(state.tirzepatideMif ?? [])],
        'question'
      );
      state.tirzepatideMif = filteredArray;
    },
    clearNutritionMif: (state) => {
      state.nutritionMif = [];
    },
    clearTirzepatideMif: (state) => {
      state.tirzepatideMif = [];
    }
  }
});

export const {
  setMifInformation,
  setMifByCompleteAccount,
  clearMifState,
  setMifPick,
  setNutritionMif,
  clearNutritionMif,
  setTirzepatideMif,
  clearTirzepatideMif
} = mifSlice.actions;

export default mifSlice.reducer;
