import classNames from 'classnames';

import { parseDecimal } from 'utils/helpers';

import { Props } from './updatePlanCard.types';

const UpdatePlanCard: React.FC<Props> = ({
  plan,
  previewChangeSubscription,
  creditBalance,
  oldPlan,
  additionalPurchaseInfo
}) => {
  const oldMonths = oldPlan?.pricePoint.paymentInterval.qty;
  const months = plan?.pricePoint.paymentInterval.qty;

  const rowClassName = 'mb-1 flex justify-between md:text-lg font-bold md:mb-0.5 gap-1';
  const rowWrapperClassName = 'mb-4 md:mb-3';
  const subheadingClassName = 'block max-w-[60%] text-mSm md:text-sm';

  return (
    <div className="rounded-2xl border border-secondary-200 bg-secondary-50 px-4 py-6 md:p-6">
      <div>
        {oldPlan && oldMonths && (
          <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
            <div className={rowClassName}>
              <span className="block">Old plan</span>
              <span className="line-through" data-testid="old_monthly_price">{`$${
                Number(oldPlan.pricePoint.totalCost) -
                (Number(previewChangeSubscription?.discount?.discountAmount) || 0)
              }/${oldMonths > 1 ? `every ${oldMonths}` : ''} mo`}</span>
            </div>
            <span className={subheadingClassName}>{oldPlan.planName}</span>
          </div>
        )}
        <div className={classNames(rowWrapperClassName, 'text-secondary')}>
          <div className={rowClassName}>
            <span className="block">New plan</span>
            <span className="block">
              {Number(previewChangeSubscription.newDiscount?.discountAmount) > 0 ? (
                <span className="font-inherit inline-flex items-center gap-1">
                  <span className="line-through opacity-30">${plan.pricePoint.totalCost}</span>
                  <span>
                    $
                    {parseDecimal(
                      Number(plan.pricePoint.totalCost) -
                        Number(previewChangeSubscription.newDiscount?.discountAmount)
                    )}
                  </span>
                </span>
              ) : (
                <span data-testid="new_monthly_price">{`$${
                  plan.pricePoint.totalCost
                }/${months > 1 ? `every ${months}` : ''} mo`}</span>
              )}
            </span>
          </div>
          <span className={subheadingClassName}>
            {plan.planName}
            <span className="block">{`Renews at $${Number(plan.pricePoint.totalCost) - Number(previewChangeSubscription.newDiscount?.discountAmount ?? 0)}`}</span>
          </span>
        </div>
        {!!Number(previewChangeSubscription.proratedAdjustment) && (
          <div className={classNames(rowWrapperClassName, 'text-primary')}>
            <div className={rowClassName}>
              <span>Prorated adjustment</span>
              <span>-${previewChangeSubscription.proratedAdjustment}</span>
            </div>
            <span className={subheadingClassName}>
              {previewChangeSubscription.billingPeriodDaysRemaining} days remaining in your current
              plan
            </span>
          </div>
        )}
        {!!Number(previewChangeSubscription.creditUsed) && (
          <div className={classNames(rowWrapperClassName, 'text-primary')}>
            <div className={rowClassName}>
              <span>LifeMD Credit applied</span>
              <span>-${previewChangeSubscription.creditUsed}</span>
            </div>
            <span className={subheadingClassName}>Available credit: ${creditBalance}</span>
          </div>
        )}
        {!!additionalPurchaseInfo && (
          <div className={classNames('mb-4 md:mb-4', additionalPurchaseInfo.color)}>
            <div className="flex justify-between font-bold md:text-lg">
              <h3>{additionalPurchaseInfo.header}</h3>
              <span>${additionalPurchaseInfo.price}</span>
            </div>
            {!!additionalPurchaseInfo.subheader && (
              <span className="block text-left text-sm">{additionalPurchaseInfo.subheader}</span>
            )}
          </div>
        )}
        {/* {!!discount && (
          <div className={classNames(rowWrapperClassName, 'text-orange')}>
            <div className={rowClassName}>
              <span>Discount</span>
              <span>-${discount.discountAmount}</span>
            </div>
            <span className={subheadingClassName}>{discount.title}</span>
          </div>
        )} */}
        <div className="my-4 h-px w-full bg-secondary" />
        <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
          <div className={rowClassName}>
            <span>
              {previewChangeSubscription.migrationHasPayment ? 'Total due now' : 'Credit applied'}
            </span>
            <span className={previewChangeSubscription.migrationHasPayment ? '' : 'text-green'}>
              {previewChangeSubscription.migrationHasPayment
                ? `$${parseDecimal(previewChangeSubscription.paymentDue)}`
                : `+$${previewChangeSubscription.creditApplied}`}
            </span>
          </div>
        </div>
      </div>
      <p className="text-mSm md:text-sm">
        {Number(previewChangeSubscription.proratedAdjustment) > 0
          ? `Your new membership begins immediately, and the cost has been prorated based on the time remaining on your current plan. Your next bill will be for $${Number(plan.pricePoint.totalCost) - Number(previewChangeSubscription.newDiscount?.discountAmount ?? 0)}.`
          : `Your new membership begins immediately. Plan renews at ${Number(plan.pricePoint.totalCost) - Number(previewChangeSubscription.newDiscount?.discountAmount ?? 0)}`}
      </p>
    </div>
  );
};

export default UpdatePlanCard;
