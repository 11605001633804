import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { LabItem as LabItemType } from 'services/auth/auth.types';

import useWidth from 'hooks/useWidth';
import { cutURLSlugs } from 'utils/helpers';

import LabCorp from 'assets/images/logos/labcorp.svg?react';
import Quest from 'assets/images/logos/quest.png';

interface LabItemProps {
  item: LabItemType;
  loading: boolean;
  onSelect: () => void;
  selectedLabAddress?: string;
}

const LabItem: React.FC<LabItemProps> = ({ item, onSelect, loading }) => {
  const { isMobile } = useWidth();
  const [isSelected, toggle] = useToggle(false);

  const handleSelect = () => {
    toggle();
    onSelect();
    setTimeout(() => {
      toggle(false);
    }, 2000);
  };

  return (
    <div
      className="flex flex-col gap-5 rounded-2xl border border-gray-200 bg-white p-4"
      data-testid="lab"
    >
      <div className="flex items-center gap-2">
        <span className="block size-8">
          {item.labType === 'quest' ? (
            <img className="size-full" src={Quest} />
          ) : (
            <LabCorp className="size-full" />
          )}
        </span>
        <span className="font-bold">{item.displayName}</span>
        {/* <span className="ml-auto">0.4 ml</span> */}
      </div>
      <div>
        <span className="max-2-[200px] font-semibold">{item.address}</span>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col text-sm">
          {item.openHours?.map((hour, index) => <span key={index}>{hour}</span>)}
        </div>
        <div className="flex flex-col gap-2">
          {!!item.phone && (
            <div className="flex items-center gap-1 text-mXs">
              <Common.Icon className="size-4" name="phone" />
              <span>{item.phone}</span>
            </div>
          )}
          {!!item.website && (
            <div className="flex items-center gap-1 text-mXs">
              <Common.Icon className="size-4" name="bandage" />
              <a className="max-w-full break-words" href={item.website} target="_blank">
                {cutURLSlugs(item.website)}
              </a>
            </div>
          )}
        </div>
      </div>
      <Common.Button
        color="blue"
        dataTestId="select_lab_btn"
        disabled={loading}
        isLoading={loading && isSelected}
        size={isMobile ? 'lg' : 'md'}
        fullWidthOnMobile
        onClick={handleSelect}
      >
        Select
      </Common.Button>
    </div>
  );
};

export default LabItem;
