import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectOrchestrate } from 'store';
import { OrchestrateState } from 'store/orchestrate-signup/orchestrate.types';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import { STATES_WHERE_ASH_KIT_IS_DISABLED } from 'constants/onboarding';
import { useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { FlowTypes } from 'utils/enums';
import { buildSearch } from 'utils/helpers';

import Heading from '../../WM/parts/Heading';
import FailedState from '../FailedState';

const KidneyStones: React.FC<AltSignupStepProps> = ({ onContinue, selectedFlow }) => {
  const { send, isLoading, handleProspect } = useSubmitOrchestrateForm();
  const [activeItem, setActiveItem] = useState<boolean>();
  const {
    user: { state }
  } = useAppSelector(selectOrchestrate);

  const subStep = useQuery().get('subStep');
  const navigate = useNavigate();

  const handleSelect = (v: boolean) => {
    if (!!v) {
      navigate({
        search: buildSearch(selectedFlow === 'authorized' ? 's=kidney-stones' : '', 'subStep=dq')
      });
    } else {
      setActiveItem(v);
      const body: OrchestrateState['mif_qa'] = [{ answer: v, question: 'kidney_stones' }];
      if (selectedFlow === FlowTypes.TripleTherapy || selectedFlow === 'authorized') {
        body.push({
          answer: STATES_WHERE_ASH_KIT_IS_DISABLED.includes(state) ? 'elation' : '',
          question: 'labs'
        });
      }
      send('mif_qa', body, () => {
        setActiveItem(undefined);
        onContinue();
      });
    }
  };

  return (
    <div className="flex h-full flex-col gap-6">
      {subStep === 'dq' ? (
        <FailedState
          onClickOnBackButton={() =>
            handleProspect(
              {
                answer: true,
                question: 'kidney_stones'
              },
              selectedFlow === 'authorized'
            )
          }
        />
      ) : (
        <>
          <Heading category="Plan" title="Do you have any history of kidney stones?" />
          <RadioGroup
            isLoading={isLoading}
            items={YES_NO_OPTIONS}
            selectedItem={activeItem}
            onSelect={handleSelect}
          />
        </>
      )}
    </div>
  );
};

export default KidneyStones;
